



































































































































































































































































































































































































@import "@/assets/css/project-variables";

.builder-container {
  width: 100%;

}

.builder-container-col {
  display: inline-block;
  /*border-left: 1px solid red;*/
}

.builder-container-row {
  /*border-bottom: 1px solid red;*/
}

.builder-container-row-name {
  min-width: 200px;
  display: inline-block;
  vertical-align: top;
  padding: 5px;
}

.builder-container .el-checkbox-button {
  width: 100%;
}

.builder-container .el-checkbox-button__inner {
  width: 100%;
}

.builder-container-row-fields {
  border: 1px solid $--color-primary;
  padding: 5px;
  background-color: white;
  //margin-bottom:10px;
}


.smooth-dnd-container.horizontal.selected-field-container {
  display: inline-block;
}

.smooth-dnd-container.selected-field-container, .selected-field-container {
  position: relative;
  border: 2px dashed #aaa;
  background-color: #f8f8f8;
  min-height: 90px !important;
  width: 100%;
  overflow: auto;
}

.selected-field-container-hint {
  display: inline-block;
  position: absolute;
  top: 40px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #575656;
}

.builder-container .dndrop-container.horizontal {
  display: flex !important;
}

.selected-field-nested-or {
  cursor: move;
  text-align: center;
  border: 1px solid $--color-primary;
  margin: 5px;
  background-color: #c2c2c2;
  min-width: 250px;
}

.selected-field-item {
  cursor: move;
  text-align: center;
  border: 1px solid $--color-primary;
  margin: 5px;
  background-color: white;
}

.selected-field-item-relation {
  padding: 10px;
  background-color: $--color-primary;
  color: black;
}

.selected-field-item-name {
  padding: 10px;
}

.node-element-drag-ghost {
  padding: 0 10px;
  border: 1px solid $--color-primary;
  background-color: white;
  display: inline-block;
}


.builder-container .el-checkbox-button__inner {
  padding-right: 5px;
}


